/* tslint:disable */

// AUTO-GENERATED FILE. DO NOT EDIT.

export const ACTIVE_SERVER_ID: number = 202;
export const STATIC_DOMAINS = {"s3": ["mkczimgmodrykonik.vshcdn.net"], "asset": ["mkczasmodrykonik.vshcdn.net"], "files": ["filesmodrykonik.vshcdn.net"]};
export const IS_PROD = true;
export const JAM_ENDPOINT = "wss://cjam.modrykonik.com";
export const API_ENDPOINT = "https://www.modrykonik.cz";
export const BASE_URL = "https://www.modrykonik.cz";
export const FACEBOOK_APP_ID = "152310534815693";
export const GCSE_CX = "2d956557d32d9a1c9";
export const WEBPUSH_PUBLIC_KEY = "BBWiPQHxnb0Jdbde9jzJE2gph_i75C4rtmBUS4y195a14HFQoPF4U1hQjYMehqSnNMFuzGk1xwK1dCMxYYZdvlI";
export const CURRENT_EUR_TO_CZK_RATE = 25.097;
export const ESHOP_URL = "https://shop.modrykonik.cz/";
