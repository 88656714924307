import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { count_WIKI_ARTICLE_EXPERIENCES } from 'mk/autogenerated/translations/ReviewArticlePreview.c484978132d7b0861db4e753ba879d99'
import { photoUrl } from 'mk/photo/photoUrl';
import { getPhotoVersionFactory } from 'mk2/apps/wiki/helpers';
import { getWikiArticleUrl } from 'mk2/apps/wiki/urls';
import { RenderSnippetAs } from 'mk2/apps/wiki/utils';
import { Clamp } from 'mk2/components/Clamp';
import { Dot } from 'mk2/components/Dot';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { StarsRating } from 'mk2/components/StarsRating';
import { astToHtml, defaultFilterAdditionalData, ShowPhotosAndVideos } from 'mk2/helpers/article_ast/filters';
import { getAllDescendantsOfAType } from 'mk2/helpers/article_ast/utils';
import { cacheLast } from 'mk2/helpers/cache';
import { hasVal } from 'mk2/helpers/sanitize';
import { findCoverPhotoEntityFromAst } from 'mk2/helpers/structuredData';
import { WikiArticleEntity } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './ReviewArticlePreview.mscss';

export enum ThumbnailSize {
    // check storybook
    LARGEST = 4,
    LARGE = 1,
    SMALL = 2,
    SMALLEST = 3,
}
export enum LayoutType {
    // check storybook
    DEFAULT = 1,
    COMPACT = 2,
    HORIZONTAL = 3,
}

const params = {
    [ThumbnailSize.LARGEST]: {
        imgW: 160,
        imgH: 160,
        imgPlaceholderClassName: styles.ReviewArticlePreview__image__placeholder160,
        thumbVersions: ['s246x246', 's560x560', 's1600x1600'],
        cropMode: ImgCropMode.Embed,
    },
    [ThumbnailSize.LARGE]: {
        imgW: 80,
        imgH: 80,
        imgPlaceholderClassName: styles.ReviewArticlePreview__image__placeholder80,
        thumbVersions: ['snx100', 's246x246', 's150x150', 's560x560', 's1600x1600'],
        cropMode: ImgCropMode.Cover,
    },
    [ThumbnailSize.SMALL]: {
        imgW: 64,
        imgH: 64,
        imgPlaceholderClassName: styles.ReviewArticlePreview__image__placeholder64,
        thumbVersions: ['snx100', 's246x246', 's150x150', 's560x560', 's1600x1600'],
        cropMode: ImgCropMode.Cover,
    },
    [ThumbnailSize.SMALLEST]: {
        imgW: 50,
        imgH: 50,
        imgPlaceholderClassName: styles.ReviewArticlePreview__image__placeholder50,
        thumbVersions: ['snx100', 's246x246', 's150x150', 's560x560', 's1600x1600'],
        cropMode: ImgCropMode.Cover,
    },
};

interface OwnProps {
    article: WikiArticleEntity;
    thumbnailSize?: ThumbnailSize;
    layout?: LayoutType;
    className?: string;
    showPerex?: boolean;
    style?: React.CSSProperties;
    utmParams?: Record<string, string>;
}

type Props = OwnProps;

export default class ReviewArticlePreview extends React.Component<Props> {
    public static defaultProps = {
        thumbnailSize: ThumbnailSize.LARGE,
        layout: LayoutType.DEFAULT,
    };

    private firstNotEmptyParagraphAstToHtmlCache = cacheLast(true);

    public render() {
        const { article, className, showPerex, style, thumbnailSize, layout, utmParams } = this.props;
        const photo = findCoverPhotoEntityFromAst(article.bodyAST, article.photos) ||
            (article.photos.length > 0 ? article.photos[0] : null);

        let firstNotEmptyParagraphsBodyChunks: React.ReactChild[] = null;

        if (article.bodyAST) {

            // finding first not empty paragraph
            const paragraphs = getAllDescendantsOfAType(article.bodyAST, 'paragraph');
            let firstNotEmptyParagraph = paragraphs?.[0];
            for (const paragraph of paragraphs) {
                const plainTexts = getAllDescendantsOfAType(paragraph, 'plain_text');
                let returnValue = '';
                for (const pt of plainTexts) {
                    returnValue += pt.text;
                }
                if (returnValue.replace(/\s+/g, ' ').trim()) {
                    firstNotEmptyParagraph = paragraph;
                    break;
                }
            }

            const additionalData = {
                ...defaultFilterAdditionalData,
                linkedObjects: article.bodyASTAdditionalData ? article.bodyASTAdditionalData.linkedObjects : {},
                renderSpecialWikiLinks: false,
                renderLinks: false,
                renderSnippetObjectsAs: RenderSnippetAs.HTML_LINK,
                showPhotosAndVideos: ShowPhotosAndVideos.NONE,
                customStyles: {
                    paragraph: styles.ReviewArticlePreview__perex__paragraph,
                },
            };
            firstNotEmptyParagraphsBodyChunks = this.firstNotEmptyParagraphAstToHtmlCache(() => astToHtml(firstNotEmptyParagraph, additionalData), firstNotEmptyParagraph);
        }

        const getBestVersionForThumb = getPhotoVersionFactory(params[thumbnailSize].thumbVersions);

        return (
            <Link
                to={getWikiArticleUrl(article.category.slug, article.slug, utmParams)}
                className={cx(
                    styles.ReviewArticlePreview,
                    className,
                    {
                        [LayoutType.DEFAULT]: styles['ReviewArticlePreview--default'],
                        [LayoutType.COMPACT]: styles['ReviewArticlePreview--compact'],
                        [LayoutType.HORIZONTAL]: styles['ReviewArticlePreview--horizontal'],
                    }[layout],
                )}
                style={style}
            >
                <div
                    className={cx(
                        styles.ReviewArticlePreview__headline,
                        {
                            [LayoutType.DEFAULT]: styles['ReviewArticlePreview__headline--default'],
                            [LayoutType.COMPACT]: styles['ReviewArticlePreview__headline--default'],
                            [LayoutType.HORIZONTAL]: styles['ReviewArticlePreview__headline--horizontal'],
                        }[layout],
                    )}
                >
                    <div
                        className={cx(
                            styles.ReviewArticlePreview__image,
                            {
                                [LayoutType.DEFAULT]: styles['ReviewArticlePreview__image--default'],
                                [LayoutType.COMPACT]: styles['ReviewArticlePreview__image--default'],
                                [LayoutType.HORIZONTAL]: styles['ReviewArticlePreview__image--horizontal'],
                            }[layout],
                        )}
                    >
                        {photo
                            ? <ImgCropped
                                className={styles.ReviewArticlePreview__image__img}
                                width={params[thumbnailSize].imgW}
                                height={params[thumbnailSize].imgH}
                                mode={params[thumbnailSize].cropMode}
                                imgUrl={photoUrl(photo, getBestVersionForThumb(photo))}
                                imgWidth={photo.width}
                                imgHeight={photo.height}
                                lazy
                            />
                            : <div className={params[thumbnailSize].imgPlaceholderClassName} />
                        }
                    </div>
                    <div className={styles.ReviewArticlePreview__rightColumn}>
                        <div
                            className={cx(
                                styles.ReviewArticlePreview__title,
                                {
                                    [LayoutType.DEFAULT]: styles['ReviewArticlePreview__title--default'],
                                    [LayoutType.COMPACT]: styles['ReviewArticlePreview__title--compact_horizontal'],
                                    [LayoutType.HORIZONTAL]: styles['ReviewArticlePreview__title--compact_horizontal'],
                                }[layout],
                            )}
                        >
                            <h3>
                                <Clamp clamp={2}>
                                    <div>{article.title}</div>
                                </Clamp>
                            </h3>
                        </div>
                        <div className={styles.ReviewArticlePreview__rightColumn__stats}>
                            {hasVal(article.experiencesScore) && article.experiencesCount ? (
                                <>
                                    <StarsRating starsCount={article.experiencesScore} />
                                    <Dot spaces={1} className={styles.ReviewArticlePreview__dot} />
                                    <span className={styles.ReviewArticlePreview__experiencesCount} >
                                        {interpolate(count_WIKI_ARTICLE_EXPERIENCES, {count: article.experiencesCount})}
                                        <FontAwesomeIcon icon={faComment} className={styles.ReviewArticlePreview__rightColumn__stats__icon} />
                                    </span>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                {showPerex && (
                    <div className={styles.ReviewArticlePreview__perex}>
                        <Clamp clamp={3}>
                            <div>{firstNotEmptyParagraphsBodyChunks}</div>
                        </Clamp>
                        <div className={styles.ReviewArticlePreview__perex__mask} />
                    </div>
                )}
            </Link>
        );
    }
}

interface PlaceholderOwnProps {
    thumbnailSize?: ThumbnailSize;
    className?: string;
    showPerex?: boolean;
    noBorder?: boolean;
    style?: React.CSSProperties;
}

export const ReviewArticlePreviewPlaceholder = React.memo<PlaceholderOwnProps>(({
    className,
    style,
    thumbnailSize= ThumbnailSize.LARGE,
    noBorder,
    showPerex,
}) => {
    return (
        <div
                className={cx(
                    styles.ReviewArticlePreview,
                    className,
                    noBorder
                        ? styles['ReviewArticlePreview--compact']
                        : styles['ReviewArticlePreview--default'],
                )}
                style={style}
        >
            <div className={styles.ReviewArticlePreview__headline}>
                <div className={styles.ReviewArticlePreview__image}>
                    <LoadingPlaceholder
                        width={params[thumbnailSize].imgW}
                        height={params[thumbnailSize].imgH}
                        className={styles.ReviewArticlePreview__image__img}
                    />
                </div>
                <div className={styles.ReviewArticlePreview__rightColumn}>
                    <div className={styles.ReviewArticlePreview__title}>
                        <LoadingPlaceholder
                            width={280}
                            height={22}
                            className={styles.ReviewArticlePreview__placeholder}
                        />
                    </div>
                    <div className={styles.ReviewArticlePreview__rightColumn__stats}>
                        <LoadingPlaceholder
                            width={88}
                            height={17}
                            className={styles.ReviewArticlePreview__placeholder}
                        />
                        &nbsp;&nbsp;
                        <LoadingPlaceholder
                            width={150}
                            height={17}
                            className={styles.ReviewArticlePreview__placeholder}
                        />
                    </div>
                </div>
            </div>
            {showPerex && (
                <div className={styles.ReviewArticlePreview__perex}>
                    <LoadingPlaceholder
                        width={'100%'}
                        height={57}
                    />
                    <div className={styles.ReviewArticlePreview__perex__mask} />
                </div>
            )}
        </div>
    );
});

ReviewArticlePreviewPlaceholder.displayName = 'ReviewArticlePreviewPlaceholder';
